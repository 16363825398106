<template>
  <div class="quote-list h-font-lg">
    <van-sticky>
        <van-search
            v-model="listConfig.form.param"
            background="#fff"
            show-action
            placeholder="请输入姓名/车牌号/车架号"
            @search="onSearch">
            <template #action>
                <div @click="onSearch" style="color: #35B8AD;">搜索</div>
            </template>
        </van-search>
    </van-sticky>
	<HYList ref="HYList" v-bind="listConfig">
		<template #columnHead="{ row }">
			<div class="row">
                <div class="row-head">
                    <span>报价时间：{{ dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                </div>
                <div class="row-content" :class="`status${row.insuredStatus}`">
                    <div class="h-font-bold-lg h-font-xl">{{ row.insuredOrgName || row.insuredOrgCode }}</div>
                    <div class="h-font-md"><span class="h-disable-color">车牌号：</span>{{row.plateNo}}</div>
                    <div class="h-font-md"><span class="h-disable-color">车架号：</span>{{row.frameNo}}</div>
                </div>
                <div class="row-foot h-flex h-row-between h-row-center">
                    <div>
                        <span class="h-font-sm">总保费：</span>
                        <span class="h-price-color">￥{{(Number(row.compActualPremium)+Number(row.commActualPremium)+Number(row.compVehicleTaxPremium)).toFixed(2)}}</span>
                    </div>
                    <div>
                        <van-button type="primary" size="small" @click="toDetail(row)">详细信息</van-button>
                    </div>
                </div>
			</div>
		</template>
        <template #rightTool="{ row }">
            <van-button slot="rightTool" v-if="!['7'].includes(row.insuredStatus)" type="warning" size="small" class="del-btn" @click="del(row)">删除</van-button>
        </template>
		<template #empty>
			<div class="none-tip">
				<van-image
				:src="require('@/assets/images/none_data.jpg')"
                style="margin-top: 20%;"
				/>
				<p>尊敬的用户，您当前暂无报价信息</p>
			</div>
		</template>
	</HYList>
  </div>
</template>
<script>
import HYList from '@/components/List/index'
import dayjs from 'dayjs'
import { Dialog, Toast } from "vant";
import { request } from "@/api/service";
import { mapState } from "vuex";
import common from '../common/mixin/common.js'
export default {
	name:'QuoteList',
	components: { HYList},
    mixins: [common],
	provide () {
		return {
			pageContext: this
		}
	},
	data() {
		return {
            // 报价状态
            insCompStatusMap: {
                0: '报价成功',
                1: '报价失败',
                2: '待支付',
                3: '人工核保',
                4: '核保失败',
                5: '支付成功',
                6: '支付失败',
                7: '承保成功',
            },
			listConfig:{
				api:{
					url:'/afis-auto-web/auto/policy/queryVXList'
				},
				form:{
					param:null
				},
				column:[]
			},
            formData: {}
		}
	},
	async created(){
	},
	mounted () {
    },
    computed: {
        ...mapState("car", ["quote", "quoteRes"]),
        ...mapState("user", ["info"])
    },
	methods:{
		dayjs,
        onSearch () {
            this.$refs.HYList.onRefresh()
        },
        async toDetail (row) {
            if (row.insuredStatus === '1') {
                this.getDetail(row.subBusinessNo, 'list')
            } else if (['2', '3', '1', '7'].includes(row.insuredStatus)) {
                this.$router.push({
                    path: '/miniprogram/quote/underwriting',
                    query: {
                        id: row.subBusinessNo
                    }
                })
            } else {
                this.$router.push({
                    path: '/miniprogram/quote/detail',
                    query: {
                        id: row.subBusinessNo
                    }
                })
            }
        },
        // 删除
        del (row) {
            Dialog.confirm({
                title: '提示',
                message: '确定要删除吗',
            })
            .then(() => {
                request({
                    url: `/afis-auto-web/auto/policy/delete/${row.subBusinessNo}`,
                    method: "post",
                    data: {}
                }).then(res => {
                    Toast.success('删除成功')
                    this.$refs.HYList.source = this.$refs.HYList.source.filter((item) => item.id != row.id)
                })
            }).catch(() => {
                // on cancel
            });
        }
	}
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
::v-deep .van-list{
    padding: 0 10px;
    background-color: $background-color;
}
.quote-list{
    // padding-bottom:60px;
}
.row{
    padding: 10px 0;
    &-head{
        font-size: $font-size-md;
        color: $disable-color;
        padding: 6px 20px;
        position: relative;
        &::after {
          @include hairline-bottom($border-color, $padding-xs, $padding-xs);
        }
    }
    &-content{
        padding: 6px 20px;

        background-repeat: no-repeat;
        background-position: 90% center;
        background-size: 60px;
        div{
            padding: 2px 0;
        }
    }
    .status0{
        background-image:url('./img/status_0.svg');
    }
    .status1{
        background-image:url('./img/status_1.svg');
    }
    .status2{
        background-image:url('./img/status_2.png');
    }
    .status3{
        background-image:url('./img/status_3.png');
    }
    .status4{
        background-image:url('./img/status_4.png');
    }
    .status7{
        background-image:url('./img/status_7.png');
    }
    .status6{
        background-image:url('./img/status_6.svg');
    }
    &-foot{
        border-top: 2px solid $border-color;
        padding: 6px 20px;
    }
}
::v-deep .del-btn {
    height: 100%;
    padding: 0 4px;
    font-size: 12px;
    text-align: center;
}
</style>